import omit from 'lodash/omit'
import { richTextRender } from '@lib/rich-text-base'
import type { TypeVideoPlayerFields } from '@lib/generated-types'
import VideoPlayer from '@components/video-player'
import clsx from 'clsx'
import { type ComponentPropsWithoutRef } from 'react'

interface VideoPlayerBlockProps extends ComponentPropsWithoutRef<'div'>, TypeVideoPlayerFields {
  showCaption: boolean
}

const VideoPlayerBlock = ({
  videoUrl,
  caption,
  showCaption = true,
  fullScreen,
  autoPlay,
  loop,
  className,
  ...props
}: VideoPlayerBlockProps) => {
  return (
    <VideoPlayer
      videoUrl={videoUrl}
      className={clsx(className)}
      fullScreen={fullScreen}
      autoPlay={autoPlay}
      loop={loop}
      {...omit(props, ['internalName'])}>
      {showCaption && caption && richTextRender(caption)}
    </VideoPlayer>
  )
}

export default VideoPlayerBlock
