import { Typography } from '@shc/ui'
import clsx from 'clsx'
import type { ComponentPropsWithoutRef, ReactElement } from 'react'
export interface CardProps extends ComponentPropsWithoutRef<'div'> {
  preHeading?: string
  title: string
  summary?: string
  image?: ReactElement
  link?: string
  linkIsInternal?: boolean
  onClick?: any
}

const CardLong = ({
  preHeading,
  title,
  summary,
  image,
  link,
  linkIsInternal,
  onClick,
  className,
  ...props
}: CardProps) => {
  const cardLong = (
    <article className="flex flex-col gap-4 md:flex-row md:gap-8">
      <div>{image}</div>
      <div className="flex flex-col gap-2 justify-center md:pr-8 md:py-5">
        {preHeading && <p className="text-left text-sm font-semibold">{preHeading}</p>}
        <Typography variant="h3" className={clsx('text-left', link && 'group-hover:underline')}>
          {title}
        </Typography>
        {summary && <p className="text-left text-sm font-medium">{summary}</p>}
      </div>
    </article>
  )

  return (
    <div className={className} {...props}>
      {link && (
        <a
          href={link}
          onClick={onClick}
          target={linkIsInternal ? undefined : '_blank'}
          rel={linkIsInternal ? undefined : 'noopener noreferrer'}
          className="outline-primary outline-offset-2 focus:outline-2">
          {cardLong}
        </a>
      )}
      {!link && cardLong}
    </div>
  )
}

export default CardLong
