import { useEffect, useState } from 'react'

const useUrlHash = (): string => {
  const [hash, setHash] = useState('')

  const updateHash = (str: string) => {
    if (!str) return
    setHash(str.split('#')[1])
  }

  const windowReady = typeof window !== 'undefined'

  useEffect(() => {
    if (windowReady) {
      const onWindowHashChange = () => updateHash(window.location.hash)
      window.addEventListener('hashchange', onWindowHashChange)

      updateHash(window.location.hash)

      return () => {
        window.removeEventListener('hashchange', onWindowHashChange)
      }
    }
  }, [windowReady])

  return hash
}

export default useUrlHash
