import React, { type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import type { TypeCaptionedMediaFields, TypeMediaCarouselFields } from '@lib/generated-types'
import MediaCarouselComponent from '@components/media-carousel'
import type { Entry } from 'contentful'
import { Container } from '@shc/ui'

interface MediaCarouselProps extends ComponentPropsWithoutRef<'section'>, TypeMediaCarouselFields {}

const MediaCarousel = ({ internalName, sectionLink, media, className }: MediaCarouselProps) => {
  return (
    <Container as="section" id={sectionLink} className={clsx(className)}>
      <MediaCarouselComponent media={media as Entry<TypeCaptionedMediaFields>[]} />
    </Container>
  )
}

export default MediaCarousel
