import type { Document, Block, Inline } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'
import type { Options } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import EmbeddedEntityRenderer from '@cms/renderers/embeded-entry-renderer'
import { renderMark, renderNode, renderText } from '@lib/rich-text-base'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import type { WidthKeys } from '@lib/services/image-service'
import clsx from 'clsx'

const widthMap = {
  '12 columns': 'lg:max-w-[75%]', // max width 8 columns
  '10 columns': 'lg:max-w-[80%]', // max width 8 columns
  '8 columns': 'lg:max-w-full',
}

const getEmbeddedEntryOptions = (width?: WidthKeys) => {
  return {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      let mediaQWidths = undefined
      const contentType = node.data.target.sys.contentType.sys.id

      if (contentType === 'captionedMedia') {
        mediaQWidths = generateBaseMediaQWidths(width)
      }

      return (
        <EmbeddedEntityRenderer
          embeddedEntry={node}
          extras={mediaQWidths}
          className={clsx(
            // Handle column size with max width and margin x to avoid uncessary wrappers
            'mx-auto w-full',
            // Force media/video/carousel embedded entries to have max widths.
            contentType === 'mediaCarouselBlock' && width && widthMap[width],
            contentType === 'captionedMedia' ||
              (contentType === 'campaignCaptionedMedia' && width && widthMap[width]),
            contentType === 'videoPlayer' && width && widthMap[width],
            // This class is used for .rich-text spacing; see utilities.css
            'embedded-entry'
          )}
        />
      )
    },
  } as Options['renderNode']
}

const richTextRender = (content?: Document | Block | Inline, width?: WidthKeys) => {
  const embeddedEntryOptions = getEmbeddedEntryOptions(width)
  const renderNodeWithEmbeddedEntries = { ...renderNode, ...embeddedEntryOptions }
  const options = { renderMark, renderNode: renderNodeWithEmbeddedEntries, renderText }
  return documentToReactComponents(content as Document, options)
}

export const richTextRenderFactory = (width?: WidthKeys) => (content?: Document | Block | Inline) =>
  richTextRender(content, width)
