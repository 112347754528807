import type { ImageSizes, ContentfulImagesAPI } from '@components/contentful-image'
import type { TypeCaptionedMediaFields, TypeContentBlockFields } from '@lib/generated-types'
import { campaignHeroDimensionMap } from '@lib/token-maps'
import type { Entry } from 'contentful'

export interface MediaQueryPercentageWidth {
  mediaQuery: string
  w: number
}

export type WidthKeys = TypeContentBlockFields['width']

/**
 * This function builds an array of media queries for the picture element
 * @param widthInColumns
 * @returns MediaQueryPercentageWidth[] | undefined
 */
export const generateBaseMediaQWidths = (
  widthInColumns?: WidthKeys | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
): MediaQueryPercentageWidth[] | undefined => {
  let percentageViewport

  let maxColumns = 12
  if (typeof widthInColumns === 'string') {
    maxColumns = parseInt(widthInColumns.replace(/\W/g, ''), 10)
  }
  percentageViewport = maxColumns / 12

  // Using Sharp's standard breakpoints defined in the DSM: https://sharphealthcare.invisionapp.com/dsm/sharp-health-care/dxp-redesign/nav/5fa7cb698c0120001835cd74/folder/60e8b857b69cf4312a5c45a8?mode=preview
  return [
    { mediaQuery: 'default', w: 400 },
    { mediaQuery: 'xs', w: 600 }, // 640px  - (20px * 2) = 600px
    { mediaQuery: 'sm', w: 720 }, // 768px  - (24px * 2) = 720px
    { mediaQuery: 'md', w: Math.ceil(percentageViewport * 960) }, // 1024px - (32px * 2) = 960px
    { mediaQuery: 'lg', w: Math.ceil(percentageViewport * 1140) }, // 1280px - (70px * 2) = 1140px
    { mediaQuery: 'xl', w: Math.ceil(percentageViewport * 1396) }, // 1536px - (70px * 2) = 1396px
  ]
}

export const breakpointService = {
  generateBaseMediaQWidths,
}

export const generateCaptionedImageSizes = (
  heroHeight: string,
  image: Entry<TypeCaptionedMediaFields>
): ImageSizes[] => {
  return campaignHeroDimensionMap[heroHeight].map((value) => {
    return {
      mediaQuery: value.mediaQuery,
      params: {
        w: value.params.w,
        fit: 'fill',
        f: image?.fields.focalPoint?.toLowerCase() as Lowercase<
          NonNullable<ContentfulImagesAPI['f']>
        >,
      },
    }
  })
}
