import React from 'react'
import type { Asset } from 'contentful'
import CaptionedMedia from './captioned-media'
import clsx from 'clsx'

interface BadgeProps {
  internalName: string
  image: Asset
  description?: string
  alignment: 'left' | 'center'
}

const Badge = ({ internalName, image, description, alignment }: BadgeProps) => (
  <div
    className={clsx(
      'flex',
      alignment === 'left' && 'justify-start',
      alignment === 'center' && 'justify-center'
    )}>
    <div className="pt-15">
      <CaptionedMedia
        internalName={internalName}
        focalPoint="Face"
        image={image}
        extras={undefined}
        imageClassName="max-h-[180px] h-[180px] w-auto content-center justify-center"
        aspectRatio="16:9"
      />
    </div>
    {description && <div className="pt-12 text-[42px] leading-[56px] px-20">{description}</div>}
  </div>
)

export default Badge
